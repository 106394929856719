import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { animated } from "react-spring"
import styled, { css } from "styled-components"
import useSmallBoopDown from "../../hooks/useSmallBoopDown"
import { shadow, simpleTransition, smMediaQuery, xsMediaQuery } from "../../theme/globalStyles"

interface IProps {
  title: string
  snippet?: Maybe<string>
  uri: string
  img: IGatsbyImageData
  date: string
}

const Wrapper = styled(({ ...props }) => (
  <ButtonBase component={Link} {...props} />
))<ButtonBaseProps>`
  position: relative;
  display: block;
  max-width: 350px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.tertiary.contrastText};
  margin: ${({ theme }) => theme.spacing(4)}px;
  ${shadow};

  ${xsMediaQuery(css`
    margin: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}px`};
  `)}

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    ${simpleTransition};
  }

  :hover {
    .overlay {
      background-color: rgba(0, 0, 0, 0.22);
    }
  }
`

const TextWrapper = styled.span`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.palette.tertiary.dark};
  border-top: none;
  height: 272px;
  background-color: ${({ theme }) => theme.palette.tertiary.main};

  ${smMediaQuery(css`
    height: 302px;
  `)}

  ${xsMediaQuery(css`
    height: 312px;
  `)}
`

const Title = styled(Typography)`
  color: inherit;
  font-weight: 700;
  text-align: center;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding: 0 ${({ theme }) => theme.spacing(1)}px;
`

const Date = styled(Typography)`
  color: inherit;
  font-size: 80%;
  line-height: 1.66;
  letter-spacing: 0.03333em;

  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1)}px;
  right: ${({ theme }) => theme.spacing(1)}px;
`

const Snippet = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing(2.5)}px;
`

const NieuwsCard: React.FC<IProps> = ({ title, snippet, uri, img, date }) => {
  const [style, trigger] = useSmallBoopDown()

  const blockStyle = { ...style, display: "inline-block" }

  return (
    <Wrapper to={uri} onMouseEnter={trigger}>
      <GatsbyImage image={img} alt={title} onMouseEnter={trigger} />
      <TextWrapper>
        <animated.span style={blockStyle}>
          <Title>{title}</Title>
          {snippet && (
            <Snippet>
              <Typography variant="body2">{snippet}</Typography>
            </Snippet>
          )}
        </animated.span>
      </TextWrapper>
      <Date>
        <animated.span style={blockStyle}>{date}</animated.span>
      </Date>
      <div className="overlay" />
    </Wrapper>
  )
}

export default NieuwsCard
