import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { graphql, PageProps } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import Spacer from "../components/base/spacer"
import NieuwsCard from "../components/nieuwsNav/nieuwsCard"
import ContactBanner from "../components/pageBuilder/contactBanner"
import SEO from "../components/SEO"
import { fakeBlock } from "../helpers"
import { xsMediaQuery } from "../theme/globalStyles"
import { AllNieuwsPaginaQuery } from "../__generated__/graphql-gatsby"

type IProps = PageProps<AllNieuwsPaginaQuery>

const CardsWrapper = styled(Container)`
  max-width: 1420px;
  padding: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin-bottom: ${({ theme }) => theme.spacing(5)}px;

  > a {
    margin: ${({ theme }) => theme.spacing(6)}px;

    ${xsMediaQuery(css`
      margin: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(2)}px`};
    `)}
  }
`

const NieuwsPage: React.FC<IProps> = ({ data: { allWpNieuws } }) => {
  const { nodes } = { ...allWpNieuws }
  const title = "Nieuws van Pop Up Pallets"
  const desc = "Een overzicht van al het nieuws van Pop Up Pallets"

  return (
    <>
      <SEO
        title={title}
        description={desc}
        ogTitle={title}
        ogDescription={desc}
        canonical="/nieuws/"
      />
      <Spacer height={42} />
      <Typography variant="h1" align="center" color="secondary" gutterBottom>
        Het nieuws van Pop Up Pallets
      </Typography>
      <CardsWrapper disableGutters>
        {nodes.map(node => (
          <NieuwsCard
            key={node.id}
            title={String(node.title)}
            uri={String(node.uri)}
            img={node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
            snippet={node.nieuws_fields?.samenvatting}
            date={node.date}
          />
        ))}
      </CardsWrapper>
      <ContactBanner block={fakeBlock} />
    </>
  )
}

export default NieuwsPage

export const query = graphql`
  query AllNieuwsPagina {
    allWpNieuws(sort: { fields: date, order: DESC }) {
      nodes {
        ...OtherNieuws
      }
    }
  }
`
